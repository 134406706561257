import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import wwLib from '@/wwLib';

import SignIn from '@/views/auth/SignIn.vue';
import SignUp from '@/views/auth/SignUp.vue';
import SignUpWorkspace from '@/views/auth/SignUpWorkspace.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import PaymentCallback from '@/views/PaymentCallback.vue';

import Auth from '@/views/Auth.vue';
import Guest from '@/views/Guest.vue';
import EmailVerificationCallback from './views/EmailVerificationCallback.vue';
import EmailVerification from './views/EmailVerification.vue';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            components: {
                guest: Guest,
                auth: Auth,
            },
            redirect: { name: 'workspaces' },
            children: [
                // Sign-in / Sign-up
                {
                    path: 'sign-in',
                    name: 'sign-in',
                    component: SignIn,
                },
                {
                    path: 'sign-up/:referralId?',
                    name: 'sign-up',
                    component: SignUp,
                },
                {
                    path: 'sign-up-workspace',
                    name: 'sign-up-workspace',
                    component: SignUpWorkspace,
                },
                {
                    path: 'sign-up-after',
                    name: 'sign-up-after',
                    component: () => import('./views/auth/SignUpAfter.vue'),
                    meta: { requiresGuestAuth: true, fullscreen: true },
                },
                {
                    path: 'forgot-password',
                    name: 'forgot-password',
                    component: ForgotPassword,
                },
                {
                    path: 'reset-password',
                    name: 'reset-password',
                    component: ResetPassword,
                },
                {
                    path: 'payment/callback',
                    name: 'payment-callback',
                    component: PaymentCallback,
                    meta: { requiresGuestAuth: true },
                },
                {
                    path: 'email/verification/callback',
                    name: 'email-verification-callback',
                    component: EmailVerificationCallback,
                    meta: { requiresGuestAuth: true },
                },
                {
                    path: 'email/verification',
                    name: 'email-verification',
                    component: EmailVerification,
                    meta: { requiresGuestAuth: true },
                },
                // Account settings
                {
                    path: 'account-settings',
                    name: 'account-settings',
                    component: () => import('./views/accountSettings/AccountSettings.vue'),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'referrals',
                    name: 'referrals',
                    component: () => import('./views/Referrals.vue'),
                    meta: { requiresAuth: true },
                },
                // Workspaces
                {
                    path: 'workspaces',
                    name: 'workspaces',
                    component: () => import('./views/workspaces/Workspaces.vue'),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'workspaces/:workspaceId',
                    component: () => import('./views/workspaces/workspace/Workspace.vue'),
                    props: true,
                    meta: { requiresAuth: true },
                    redirect: { name: 'workspaces-projects' },
                    children: [
                        // Project
                        {
                            path: 'projects',
                            name: 'workspaces-projects',
                            props: true,
                            component: () => import('./views/workspaces/workspace/Websites.vue'),
                            meta: { showTabs: true },
                        },
                        {
                            path: 'projects/:websiteId',
                            component: () => import('./views/workspaces/websites/Website.vue'),
                            props: true,
                            meta: { requiresAuth: true },
                            redirect: { name: 'workspaces-projects-deployments' },
                            children: [
                                {
                                    path: 'deployments',
                                    name: 'workspaces-projects-deployments',
                                    props: true,
                                    component: () => import('./views/workspaces/websites/Publication.vue'),
                                },
                                {
                                    path: 'backups',
                                    name: 'workspaces-projects-backups',
                                    props: true,
                                    component: () => import('./views/workspaces/websites/Versions.vue'),
                                },
                                {
                                    path: 'domain',
                                    name: 'workspaces-projects-domain',
                                    props: true,
                                    component: () => import('./views/workspaces/websites/Domains.vue'),
                                },
                                {
                                    path: 'self-hosting',
                                    name: 'workspaces-projects-self-host',
                                    props: true,
                                    component: () => import('./views/workspaces/websites/SelfHost.vue'),
                                },
                                {
                                    path: 'plans',
                                    name: 'workspaces-projects-plans',
                                    props: true,
                                    component: () => import('./views/workspaces/websites/Plans.vue'),
                                },
                                {
                                    path: 'settings',
                                    name: 'workspaces-projects-settings',
                                    props: true,
                                    component: () => import('./views/workspaces/websites/Settings.vue'),
                                },
                            ],
                        },
                        // Source codes
                        {
                            path: 'components',
                            name: 'workspaces-components',
                            props: true,
                            component: () => import('./views/workspaces/sourceCodes/SourceCodes.vue'),
                            meta: { showTabs: true },
                            redirect: { name: 'workspaces-components-elements' },
                            children: [
                                {
                                    path: 'elements',
                                    name: 'workspaces-components-elements',
                                    props: true,
                                    component: () => import('./views/workspaces/sourceCodes/Elements.vue'),
                                },
                                {
                                    path: 'sections',
                                    name: 'workspaces-components-sections',
                                    props: true,
                                    component: () => import('./views/workspaces/sourceCodes/Sections.vue'),
                                },
                                {
                                    path: 'plugins',
                                    name: 'workspaces-components-plugins',
                                    props: true,
                                    component: () => import('./views/workspaces/sourceCodes/Plugins.vue'),
                                },
                            ],
                        },
                        {
                            path: 'members',
                            name: 'workspaces-members',
                            props: true,
                            component: () => import('./views/workspaces/workspace/Members.vue'),
                            meta: { showTabs: true },
                        },
                        {
                            path: 'plans-billing',
                            name: 'workspaces-plans-billing',
                            props: true,
                            component: () => import('./views/workspaces/workspace/PlansBilling.vue'),
                            meta: { showTabs: true },
                        },
                        {
                            path: 'plans',
                            name: 'workspaces-plans',
                            props: true,
                            component: () => import('./views/workspaces/workspace/Plans.vue'),
                            meta: { showTabs: true },
                        },
                        {
                            path: 'self-hosting',
                            name: 'workspaces-self-host',
                            props: true,
                            component: () => import('./views/workspaces/workspace/SelfHost.vue'),
                            meta: { showTabs: true },
                        },
                        {
                            path: 'settings',
                            name: 'workspaces-settings',
                            props: true,
                            component: () => import('./views/workspaces/workspace/Settings.vue'),
                            meta: { showTabs: true },
                        },
                    ],
                },
                {
                    path: 'admin',
                    name: 'admin',
                    component: () => import('./views/admin/Admin.vue'),
                    meta: { requiresAuth: true, requiresAdmin: true },
                },
            ],
        },
        {
            path: '*',
            redirect: 'workspaces',
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    wwLib.wwPopups.openPopups.map(popup => popup.close());
    if (to.matched.some(record => record.meta.requiresAuth)) {
        try {
            store.commit('v2/setPageProgress', 0);
            setTimeout(() => {
                if (store.state.v2.pageProgress < 25) store.commit('v2/setPageProgress', 25);
            }, 100);
            await store.dispatch('v2/fetchUser');

            if (!store.state.v2.user || !store.state.v2.user.id) throw new Error('User not defined');
            if (!store.state.v2.user.isEmailVerified) return next({ path: '/email/verification' });

            if (to.matched.some(record => record.meta.requiresAdmin) && !store.state.v2.user.admin)
                return next({ path: '/workspaces' });

            //Identify Segment
            if (window.analytics)
                window.analytics.identify(store.state.v2.user.id, {
                    email: store.state.v2.user.email,
                    full_name: store.state.v2.user.name,
                    name: store.state.v2.user.name,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });

            //Indentify Crisp
            if (window.$crisp)
                window.$crisp.push([
                    'set',
                    'user:email',
                    [store.state.v2.user.email, store.state.v2.user.supportChatHash],
                ]);

            //Identify userflow
            if (window.userflow)
                window.userflow.identify(store.state.v2.user.id, {
                    name: store.state.v2.user.name,
                    email: store.state.v2.user.email,
                    signed_up_at: store.state.v2.user.createdAt,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });

            store.commit('v2/setPageProgress', 100);
            return next();
        } catch (error) {
            await store.dispatch('v2/logoutUser');
            return next({ path: '/sign-in', query: { redirectUrl: to.path } });
        }
    } else if (to.matched.some(record => record.meta.requiresGuestAuth)) {
        try {
            await store.dispatch('v2/fetchUser');
            if (!store.state.v2.user || !store.state.v2.user.id)
                return next({ path: '/sign-in', query: { redirectUrl: to.path } });

            // Special case for the verification callback to sign-up-after flow
            if (to.name === 'sign-up-after') {
                if (!store.state.v2.user.isEmailVerified) return next({ path: '/email/verification' });
                return next();
            }

            //Identify Segment
            if (window.analytics)
                window.analytics.identify(store.state.v2.user.id, {
                    email: store.state.v2.user.email,
                    full_name: store.state.v2.user.name,
                    name: store.state.v2.user.name,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });

            return next();
        } catch (error) {
            await store.dispatch('v2/logoutUser');
            return next({ path: '/sign-in', query: { redirectUrl: to.path } });
        }
    } else {
        try {
            await store.dispatch('v2/fetchUser');
            if (!store.state.v2.user || !store.state.v2.user.id || !store.state.v2.user.isEmailVerified)
                throw new Error('User not defined');
            return next({ path: to.query.redirectUrl ? to.query.redirectUrl : '/workspaces' });
        } catch (error) {
            return next();
        }
    }
});

router.afterEach(async to => {
    if (window.analytics) window.analytics.page(to.name, to.query);
    if (window.Appcues) window.Appcues.page(to.name, to.query);
});

export default router;
