<template>
    <div class="ww-popup-source-code ww-scroll-bar">
        <label class="source-code__label caption-s" for="source-code-name">
            Component name
            <span class="source-code__label-required">{{ $t('utils.required') }}</span>
        </label>
        <div class="source-code__row">
            <input
                class="source-code__input ww-editor-input -large"
                name="source-code-name"
                :disabled="edition"
                v-model="sourceCodeName"
                @input="setName"
                placeholder="name"
            />
            <wwLoaderSmall class="source-code__loader" v-if="checkingName" />
        </div>
        <label v-if="!edition && user.admin" class="source-code__label caption-s" for="source-code-name">
            Component UID (empty for random UID)
        </label>
        <div v-if="!edition && user.admin" class="source-code__row">
            <input
                class="source-code__input ww-editor-input -large"
                name="source-code-name"
                :disabled="edition"
                v-model="sourceCodeUid"
                @input="setUid"
                placeholder="UID (empty for random UID)"
            />
            <wwLoaderSmall class="source-code__loader" v-if="checkingName" />
        </div>
        <label class="source-code__label caption-s" for="source-code-name">
            Github source
            <span class="source-code__label-required">{{ $t('utils.required') }}</span>
        </label>
        <div class="group">
            <wwManagerGithubButton
                :workspaceId="workspace.id"
                :organization="githubRepository.organization"
                :repository="githubRepository.repository"
                :branch="githubRepository.branch"
                @change="setRepository($event)"
            ></wwManagerGithubButton>
        </div>
        <div class="source-code__error" v-if="sourceCodeNameError">{{ sourceCodeNameError }}</div>
        <div class="source-code__error" v-if="githubRepositoryError">{{ githubRepositoryError }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'wwPopupSourceCodeGithub',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {
        ...mapGetters({
            workspace: 'v2/getCurrentOrganization',
            user: 'v2/getUser',
        }),
    },
    watch: {
        githubRepository() {
            if (this.sourceCodeName.length < 2 || !this.githubRepository || !this.githubRepository.branch)
                this.options.setButtonState('NEXT', 'disabled');
            else this.options.setButtonState('NEXT', 'ok');
        },
        sourceCodeName() {
            if (this.sourceCodeName.length < 2 || !this.githubRepository || !this.githubRepository.branch)
                this.options.setButtonState('NEXT', 'disabled');
            else this.options.setButtonState('NEXT', 'ok');
        },
        sourceCodeNameError() {
            if (this.sourceCodeNameError) this.options.setButtonState('NEXT', 'disabled');
        },
        githubRepositoryError() {
            if (this.githubRepositoryError) this.options.setButtonState('NEXT', 'disabled');
        },
    },
    data() {
        return {
            edition: false,
            checkingName: false,
            checkingNameTimeout: null,
            sourceCodeName: null,
            sourceCodeUid: null,
            sourceCodeNameError: null,
            sourceCodeType: 'section',
            sourceCodeTypes: {
                type: 'text',
                values: [
                    {
                        value: 'section',
                        default: true,
                        text: {
                            en: 'Section',
                        },
                    },
                    {
                        value: 'wwObject',
                        text: {
                            en: 'wwObject',
                        },
                    },
                    {
                        value: 'plugin',
                        text: {
                            en: 'Plugin',
                        },
                    },
                ],
            },

            githubRepository: null,
            githubRepositoryError: null,
        };
    },
    methods: {
        ...mapActions({
            createPlugin: 'v2/createPlugin',
            createWwObjectBase: 'v2/createWwObjectBase',
            createSectionBase: 'v2/createSectionBase',
        }),
        async init() {
            this.edition = this.options.storyData.edition;

            this.sourceCodeName = this.options.data.name || '';
            this.options.result.name = this.sourceCodeName;

            this.sourceCodeType = this.options.data.type || 'section';
            this.options.result.type = this.sourceCodeType;

            this.githubRepository = this.options.data.repository || {};
            this.options.result.repository = this.githubRepository;
        },

        setName() {
            this.options.result.name = this.sourceCodeName;
        },

        setUid() {
            this.options.result.uid = this.sourceCodeUid;
        },

        setRepository(repository) {
            this.githubRepositoryError = false;

            this.githubRepository = repository;

            if (
                this.githubRepository.organization &&
                this.githubRepository.repository &&
                this.githubRepository.branch
            ) {
                this.githubRepository.type = 'github';
                this.options.result.repository = this.githubRepository;
            } else {
                this.options.result.repository = null;
            }
        },
        async beforeNext() {
            if (!this.options.result.name) {
                this.sourceCodeNameError = 'Set a name for this component.';
                return false;
            }

            if (!this.options.result.repository || this.options.result.repository == {}) {
                this.githubRepositoryError = 'Select a repository and a branch.';
                return false;
            }

            if (!this.edition) {
                this.options.setLoadingStatus(true);
                try {
                    if (this.sourceCodeType === 'wwobject')
                        await this.createWwObjectBase({
                            name: this.sourceCodeName,
                            organizationId: this.options.data.workspaceId,
                            repository: this.githubRepository,
                            type: 'github',
                            forceUid: this.sourceCodeUid,
                        });
                    else if (this.sourceCodeType === 'section')
                        await this.createSectionBase({
                            name: this.sourceCodeName,
                            organizationId: this.options.data.workspaceId,
                            repository: this.githubRepository,
                            type: 'github',
                            forceUid: this.sourceCodeUid,
                        });
                    else if (this.sourceCodeType === 'plugin')
                        await this.createPlugin({
                            name: this.sourceCodeName,
                            organizationId: this.options.data.workspaceId,
                            repository: this.githubRepository,
                            type: 'github',
                            forceUid: this.sourceCodeUid,
                        });
                } catch (err) {
                    wwLib.wwLog.error(err);

                    wwLib.wwNotification.open({
                        text: {
                            en: `<b>Error creating new component.</b> ${
                                this.sourceCodeUid ? ' Check that uid is not already in use.' : ''
                            }`,
                        },
                        color: 'red',
                        duration: '5000',
                    });
                }
                this.options.setLoadingStatus(false);
            }
            return true;
        },
    },
    created() {
        this.init();
    },
    beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.ww-popup-source-code {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .source-code {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__row {
            display: flex;
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
        }
        &__loader {
            transform: scale(0.8);
        }
        &__error {
            margin: var(--ww-spacing-04) auto;
            color: var(--ww-color-red-500);
        }
    }
}
</style>
