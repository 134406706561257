import apollo from '@/apollo';
import axios from 'axios';
import {
    // SectionBases
    CREATE_SECTION_BASE,
    UPDATE_SECTION_BASE,
    DELETE_SECTION_BASE,
    FORCE_FETCH_SECTION_BASE,
    SET_SECTION_BASE_ACTIVE_VERSION,
    // WwObjectBases
    CREATE_WWOBJECT_BASE,
    UPDATE_WWOBJECT_BASE,
    DELETE_WWOBJECT_BASE,
    FORCE_FETCH_WWOBJECT_BASE,
    SET_WWOBJECT_BASE_ACTIVE_VERSION,
    // Plugins
    CREATE_PLUGIN,
    UPDATE_PLUGIN,
    DELETE_PLUGIN,
    FORCE_FETCH_PLUGIN,
    SET_PLUGIN_ACTIVE_VERSION,
} from './sourceCode.graphql';

const apiUrl = process.env.VUE_APP_API_URL;

export default {
    /*=============================================m_ÔÔ_m=============================================\
        SectionBases
    \================================================================================================*/
    async getOrganizationSectionBases(organizationId) {
        const { data } = await axios.get(`${apiUrl}/organizations/${organizationId}/section_bases`);
        return data;
    },
    async createSectionBase(organizationId, name, repository, forceUid) {
        const result = await apollo.mutate({
            mutation: CREATE_SECTION_BASE,
            variables: {
                organizationId,
                name,
                repository,
                forceUid,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CREATE_SECTION_BASE');

        return result.data.createSectionBase;
    },
    async updateSectionBase(sectionBaseId, repository) {
        const result = await apollo.mutate({
            mutation: UPDATE_SECTION_BASE,
            variables: {
                sectionBaseId,
                repository,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_SECTION_BASE');

        return result.data.updateSectionBase;
    },
    async deleteSectionBase(sectionBaseId) {
        const result = await apollo.mutate({
            mutation: DELETE_SECTION_BASE,
            variables: {
                sectionBaseId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_SECTION_BASE');

        return result.data.deleteSectionBase;
    },
    async forceFetchSectionBase(sectionBaseId) {
        const result = await apollo.mutate({
            mutation: FORCE_FETCH_SECTION_BASE,
            variables: {
                sectionBaseId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_FORCE_FETCH_SECTION_BASE');

        return result.data.forceFetchSectionBase;
    },
    async getSectionBaseVersions(sectionBaseId) {
        const { data } = await axios.get(`${apiUrl}/section_bases/${sectionBaseId}/versions`);
        return data;
    },
    async setSectionBaseActiveVersion(sectionBaseId, sectionBaseVersionId) {
        const result = await apollo.mutate({
            mutation: SET_SECTION_BASE_ACTIVE_VERSION,
            variables: {
                sectionBaseId,
                sectionBaseVersionId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_USER_WWOBJECT_TYPES');

        return result.data.setSectionBaseActiveVersion;
    },
    async addSectionBaseMember(sectionBaseId, organizationId) {
        await axios.put(`${apiUrl}/section_bases/${sectionBaseId}/members/${organizationId}`);
    },
    async removeSectionBaseMember(sectionBaseId, organizationId) {
        await axios.delete(`${apiUrl}/section_bases/${sectionBaseId}/members/${organizationId}`);
    },
    /*=============================================m_ÔÔ_m=============================================\
        WwObjectBases
    \================================================================================================*/
    async getOrganizationWwObjectBases(organizationId) {
        const { data } = await axios.get(`${apiUrl}/organizations/${organizationId}/wwobject_bases`);
        return data;
    },
    async createWwObjectBase(organizationId, name, repository, type, forceUid) {
        const result = await apollo.mutate({
            mutation: CREATE_WWOBJECT_BASE,
            variables: {
                name,
                organizationId,
                repository,
                type,
                forceUid,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CREATE_WWOBJECT_BASE');

        return result.data.createWwObjectBase;
    },
    async updateWwObjectBase(wwObjectBaseId, repository) {
        const result = await apollo.mutate({
            mutation: UPDATE_WWOBJECT_BASE,
            variables: {
                wwObjectBaseId,
                repository,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_WWOBJECT_BASE');

        return result.data.updateWwObjectBase;
    },
    async deleteWwObjectBase(wwObjectBaseId) {
        const result = await apollo.mutate({
            mutation: DELETE_WWOBJECT_BASE,
            variables: {
                wwObjectBaseId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_WWOBJECT_BASE');

        return result.data.deleteWwObjectBase;
    },
    async forceFetchWwObjectBase(wwObjectBaseId) {
        const result = await apollo.mutate({
            mutation: FORCE_FETCH_WWOBJECT_BASE,
            variables: {
                wwObjectBaseId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_FORCE_FETCH_WWOBJECT_BASE');

        return result.data.forceFetchWwObjectBase;
    },
    async getWwObjectBaseVersions(wwObjectBaseId) {
        const { data } = await axios.get(`${apiUrl}/wwobject_bases/${wwObjectBaseId}/versions`);
        return data;
    },
    async setWwObjectBaseActiveVersion(wwObjectBaseId, wwObjectBaseVersionId) {
        const result = await apollo.mutate({
            mutation: SET_WWOBJECT_BASE_ACTIVE_VERSION,
            variables: {
                wwObjectBaseId,
                wwObjectBaseVersionId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_USER_WWOBJECT_TYPES');

        return result.data.setWwObjectBaseActiveVersion;
    },
    async addWwObjectBaseMember(wwObjectBaseId, organizationId) {
        await axios.put(`${apiUrl}/wwobject_bases/${wwObjectBaseId}/members/${organizationId}`);
    },
    async removeWwObjectBaseMember(wwObjectBaseId, organizationId) {
        await axios.delete(`${apiUrl}/wwobject_bases/${wwObjectBaseId}/members/${organizationId}`);
    },
    /*=============================================m_ÔÔ_m=============================================\
        Plugins
    \================================================================================================*/
    async getOrganizationPlugins(organizationId) {
        const { data } = await axios.get(`${apiUrl}/organizations/${organizationId}/plugins`);
        return data;
    },
    async createPlugin(organizationId, name, repository, forceUid) {
        const result = await apollo.mutate({
            mutation: CREATE_PLUGIN,
            variables: {
                name,
                organizationId,
                repository,
                forceUid,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CREATE_PLUGIN');

        return result.data.createPlugin;
    },
    async updatePlugin(pluginId, repository) {
        const result = await apollo.mutate({
            mutation: UPDATE_PLUGIN,
            variables: {
                pluginId,
                repository,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_PLUGIN');

        return result.data.updatePlugin;
    },
    async deletePlugin(pluginId) {
        const result = await apollo.mutate({
            mutation: DELETE_PLUGIN,
            variables: {
                pluginId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_PLUGIN');

        return result.data.deletePlugin;
    },
    async forceFetchPlugin(pluginId) {
        const result = await apollo.mutate({
            mutation: FORCE_FETCH_PLUGIN,
            variables: {
                pluginId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_FORCE_FETCH_PLUGIN');

        return result.data.forceFetchPlugin;
    },
    async getPluginVersions(pluginId) {
        const { data } = await axios.get(`${apiUrl}/plugins/${pluginId}/versions`);
        return data;
    },
    async setPluginActiveVersion(pluginId, pluginVersionId) {
        const result = await apollo.mutate({
            mutation: SET_PLUGIN_ACTIVE_VERSION,
            variables: {
                pluginId,
                pluginVersionId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_USER_WWOBJECT_TYPES');

        return result.data.setPluginActiveVersion;
    },
    async addPluginMember(pluginId, organizationId) {
        await axios.put(`${apiUrl}/plugins/${pluginId}/members/${organizationId}`);
    },
    async removePluginMember(pluginId, organizationId) {
        await axios.delete(`${apiUrl}/plugins/${pluginId}/members/${organizationId}`);
    },
};
