<template>
    <div v-if="!isVerified" class="relative">
        <div v-if="msg" class="paragraph-l mb-4 pt-6" style="border-top: 1px solid var(--ww-color-dark-300)">
            {{ msg }}
        </div>
    </div>
    <div v-else>
        <div class="paragraph-m mb-4 pt-6" style="border-top: 1px solid var(--ww-color-dark-300)">
            Your email has been verified.
        </div>
        <div class="caption-l flex flex-col items-center mb-4">
            <span>Please wait...</span>
            <wwLoaderSmall class="column-loader mt-4" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVerified: true,
            msg: null,
        };
    },
    methods: {
        async redirectToSignUpAfter() {
            this.isVerified = true;
            setTimeout(() => {
                this.$router.replace({ name: 'sign-up-after' });
            }, 3000);
        },
    },
    async mounted() {
        const { code, redirectUrl } = this.$router.currentRoute.query;

        switch (code) {
            case 'missing-token':
                this.msg = 'Unable to verify email: Missing token';
                break;
            case 'invalid-token':
                this.msg = 'Unable to verify email: Invalid token';
                break;
            case 'expired-token':
                this.msg = 'Unable to verify email: Expired token';
                break;
            case 'verified':
                this.redirectToSignUpAfter();
                break;
            default:
                if (redirectUrl === '/sign-up-after') {
                    this.redirectToSignUpAfter();
                } else {
                    this.msg = 'Email verification';
                }
        }
    },
};
</script>
